import React, {Component} from 'react';
import Partidos from './components/partidos';

class App extends Component {
    render() {
        return (
            <Partidos partidos ={this.state.partidos} />
        )
    }

    state = {
        partidos: []
    };

    componentDidMount() {
        fetch('http://192.168.1.30:5000/')
            .then(res => res.json())
            .then((data) => {
                // console.log(data.partidos)
                this.setState({ partidos : data.partidos })
            })  
            .catch(console.log)
    }
}

export default App;