import React from 'react'

const Partidos = ({partidos}) => {
    // console.log(partidos)
    return (
        
        <div>
            <center><h1>Lista de próximos partidos</h1></center>
            {partidos.map((partido) => (
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">{partido.categoria}</h5>
                        <h6 class="card-subtitle mb-2 text-muted">Lugar: {partido.donde}</h6>
                        <p class="card-text">Día {partido.dia}</p>
                        <p class="card-text">Hora{ partido.hora}</p>
                        <p class="card-text">Local {partido.local}</p> 
                        <p class="card-text">Visitante {partido.visitante}</p> 
                    </div>
                </div>
            ))}
        </div>
    )
};

export default Partidos